function Youtube(){

    return(
        <div>
            <br/>
            <h2>What is Tezos?</h2>
            <br/>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/Umy75ihttwo?si=578wJ1uW6j3JBsd7" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            <br/>
            <br/>
            <h2>How do i delegate with temple wallet?</h2>
            <br/>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/iFH8WEfuaDI?si=FWWxBht2qxpwpM-E" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allowFullScreen></iframe>
            <br/>
       
        </div>
       
    )
}
export default Youtube;