function Introduction(){

    return(
        <div style={{textAlign:"left", maxWidth:"80%"}}>
            <p>
            Introducing Ageaenes Bakery
            Established in April 2024, Ageaenes Tezos Bakery represents a contribution to the realm of decentralized finance,
            offering investors a secure and lucrative avenue to participate in the Tezos blockchain ecosystem.
            With a focus on empowering individuals to harness the potential of their digital assets, 
            Ageaenes Bakery combines technology with a commitment to transparency and reliability.
            This venture stands at the forefront of innovation,
            providing a robust platform for staking Tezos and earning rewards while contributing to the network's security and stability.
           </p>
        </div>
        
    )

}
export default Introduction