import ProgressBar from 'react-bootstrap/ProgressBar';
import JsonData from './ageaenes.json'

function StakingProgressBar(props: any) {
  const balance = props.balance;
  const capacity = props.capacity;

  return <div style={{width:"100%"}}>
      <ProgressBar now={balance} max={capacity}   />
  </div>;
}

export default StakingProgressBar;