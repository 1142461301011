import bakerylogo from './images/bakerylogo.png';
import tezoslogo from './images/tezos-blue.png';
import './App.css';
import './css/image.css'
import JsonDataDisplay from './JsonDataDisplay'
import Introduction from './Introduction'
import 'bootstrap/dist/css/bootstrap.min.css';
import xtwitter from 'bootstrap-icons/icons/twitter-x.svg'
import discord from 'bootstrap-icons/icons/discord.svg'
import github from 'bootstrap-icons/icons/github.svg'
import StakingProgressBar from './StakingProgressBar';
import CopyToClipboardHash from './CopyToClipboardHash';
import Youtube from './Youtube';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="flex-container" style={{display:"inline-flex"}} >
            <div>
              <img src={bakerylogo} className='image' alt="Logo" />
            </div>
            <div>
              <h1>Ageaenes Bakery</h1>
              <div>
              <div className="info_social">
                <a href="https://twitter.com/ageaenes">
                  <img src={xtwitter} style={{width:"75px"}}></img>
                </a>
                {" "}
                <a href="https://discord.gg/mkWF6sYBQt">
                  <img src={discord} style={{width:"75px"}}></img>
                </a>
                {" "}
                <a href="https://github.com/ageaenes">
                  <img src={github} style={{width:"75px"}}></img>
                </a>
              </div>

              </div>
            </div>
            <div>
              <img src={tezoslogo} className='image' alt="Logo" />
            </div>
            
          </div>

      </header>
        <div className="App-container">
          
          <br/>
          <div>
            
            <CopyToClipboardHash></CopyToClipboardHash>
          </div>
          
          
          <JsonDataDisplay/> 
          <Introduction/>
          <Youtube/>
        </div>
      
    </div>
  );
}

export default App;
