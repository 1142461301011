import JsonData from './ageaenes.json'
import React from 'react'
import './css/flex.css'
import Table from 'react-bootstrap/Table';
import StakingProgressBar from './StakingProgressBar';

function JsonDataDisplay(){
    const divisor = 1000000
    const delegators=JsonData.numDelegators
    const balance=JsonData.balance/divisor
    const capacity= balance*9
    const delegatedBalance=JsonData.delegatedBalance/divisor
    const available=capacity-delegatedBalance
    
    //Staking data
    const stakers=JsonData.stakersCount
    const externallyStaked=JsonData.externalStakedBalance/divisor
    const stakedBalance = JsonData.stakedBalance/divisor
    const stakingCapacity = (stakedBalance * 5)
    const availableStakedBalance =(stakingCapacity-externallyStaked)
    const stakingFee= JsonData.edgeOfBakingOverStaking /(divisor*10)


    return(
        <div>
            <div className='table-wrapper'>
                <h2>Delegation</h2>
                <StakingProgressBar balance={delegatedBalance} capacity={capacity}/>
                <br></br>
                <Table bordered hover variant="dark" >
                    <thead>
                        <tr>
                        <th>Delegators</th>
                        <th>Total delegated</th>
                        <th>Available delegation</th>
                        <th>Min delegation</th>
                        <th>Fee</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{delegators}</td>
                            <td>{delegatedBalance}</td>
                            <td>{available}</td>
                            <td>0.1tz</td>
                            <td width="100%">5%</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            <div className='table-wrapper' >
                <h2>Staking</h2>
                <StakingProgressBar balance={externallyStaked} capacity={stakingCapacity}/>
                <br></br>
                <Table bordered hover variant="dark" >
                    <thead>
                        <tr>
                        <th>Stakers</th>
                        <th>Stakers stake</th>
                        <th>External stake</th>
                        <th>Available stake</th>
                        <th>Fee</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{stakers}</td>
                            <td>{stakedBalance}</td>
                            <td>{externallyStaked}</td>
                            <td>{availableStakedBalance}</td>
                            <td width="100%">{stakingFee}%</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    )
        
    
}
export default JsonDataDisplay;