import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Clipboard } from 'react-bootstrap-icons';
import Toast from 'react-bootstrap/Toast';

export default function CopyToClipboardHash() {
  const [textToCopy, setTextToCopy] = useState(''); // The text you want to copy
  const [copyStatus, setCopyStatus] = useState(false); // To indicate if the text was copied

  const onCopyText = () => {
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 2000); // Reset status after 2 seconds
  };

  return (
    <div>
      <div  className="flex-container" style={{ alignItems:"baseline"}}>
        <div style={{marginRight:"10px"}}>
          <p>tz1brWSr91ZygR4gi5o19yo8QMff926y2B5e</p>
        </div>
        <div style={{marginRight:"10px"}}>
          <CopyToClipboard text="tz1brWSr91ZygR4gi5o19yo8QMff926y2B5e" onCopy={onCopyText} >
            <Clipboard/>
          </CopyToClipboard>
        </div>
        <div>
        {copyStatus && 
        <div>
          <p>Copied</p>
        </div>
      }
      </div>
      </div>
      <br/>
      
    </div>
    
  );
}